/* eslint-disable camelcase */

declare let __webpack_chunk_load__; // https://webpack.js.org/api/module-variables/#__webpack_chunk_load__-webpack-specific

const isMobileApp = ENV.APP.platform === 'ios' || ENV.APP.platform === 'android';

if (!ENV.isDevelopment && !isMobileApp && !window.Cypress) {
    // Store reference to existing global function
    const originalLoad = __webpack_chunk_load__;

    // We're overriding Webpack's internal functionality to enable
    // retrying failed attempts to download "chunks"
    // (which are produced as a result of code splitting)
    __webpack_chunk_load__ = id => {
        let retryAttempt = 0;

        return promiseWithRetry(
            () => {
                if (retryAttempt > 0) {
                    console.warn(`Retrying loading chunk ${id} after ${retryAttempt} failed attempt(s)`);
                }
                retryAttempt++;

                return originalLoad(id);
            },
            {
                maxRetryAttempts: 3,
                scalingDuration: 100,
            },
        );
    };
}
/* eslint-enable camelcase */

/* eslint-disable import/newline-after-import */
/* eslint-disable import/order */
import React from 'react';
import {createRoot} from 'react-dom/client';
import 'rxjs';
import 'rxjs-compat'; // Should be removed once migration is done https://auth0.com/blog/whats-new-in-rxjs-6/
import 'web-app/setup/prototype-extensions/load-prototype-extensions';
import extendNumber from 'web-app/setup/prototype-extensions/number';
extendNumber();

import 'url-polyfill'; // URL polyfill. The whatwg-url polyfill did not work in IE11 with our project for some reason'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'; // Cancellable fetch

import setupSentry from 'web-app/shared/sentry-setup';
import setupMoment from 'web-app/setup/moment-setup';
import setupNumeral from 'web-app/setup/numeral-setup';
import ENV from 'web-app/config/environment';
import {promiseWithRetry} from 'web-app/util/rxjs';

import {withTranslationsReady$} from './react/routes/application-route-helpers/with-translations';

setupMoment();
setupNumeral();
setupSentry(ENV as any);

withTranslationsReady$.subscribe(() => {
    import('./webpack-react-app').then(App => {
        const root = createRoot(document.getElementById('webpack-root')!);
        root.render(<App.default />);
    });
});
