/* eslint-disable quotes */
const {
    getAPIConfigs,
    allTLDs,
    APP_DEVELOPMENT_HOST,
    determineMobileApp,
    brightHorizonsImgService,
    brightHorizonsApiService,
    busyBeesImgService,
    busyBeesApiService,
} = require('../app/shared/environment-helpers');
const {PLATFORMS, ENV} = require('../app/shared/constants');

module.exports = environment => {
    const env = environment || ENV.LOCAL;

    const isDevelopment = process.env.NODE_ENV !== 'production';
    const isDevMachineBuild = process.env.DEV_MACHINE_BUILD === 'true';
    const isFastBuild = (process.env.FAST_BUILD ?? 'false').trim().toLowerCase() === 'true';
    const webpackVersion = process.env.WEBPACK_VERSION;
    const CURRENT_PLATFORM = process.env.FAMLY_PLATFORM || PLATFORMS.WEB;
    const CURRENT_STORE = process.env.STORE;
    const CURRENT_WHITELABEL_APP_ID = process.env.WHITELABEL_APP_ID;
    const REMOTE_DEV_HOST = process.env.REMOTE_DEV_HOST;
    const IMAGE_HOST = process.env.IMAGE_HOST;
    const SENTRY_TARGET = process.env.SENTRY_TARGET;

    const apiConfigs = getAPIConfigs(
        env,
        CURRENT_PLATFORM,
        isDevMachineBuild || isDevelopment,
        CURRENT_WHITELABEL_APP_ID,
        REMOTE_DEV_HOST,
    );

    const isMobileApp = CURRENT_PLATFORM === PLATFORMS.IOS || CURRENT_PLATFORM === PLATFORMS.ANDROID;

    return {
        modulePrefix: 'web-app',
        environment: env,
        rootURL: isMobileApp ? '' : '/',
        locationType: 'hash',
        releaseUrl: '/release.txt',
        contentSecurityPolicy: contentSecurityPolicy(
            CURRENT_PLATFORM,
            apiConfigs,
            isDevelopment,
            REMOTE_DEV_HOST,
            isFastBuild,
            IMAGE_HOST,
        ),
        contentSecurityPolicyMeta: true,
        isDevelopment,
        isFastBuild,
        isDevMachineBuild,
        EmberENV: {
            FEATURES: {},
            LOG_VERSION: false,
        },
        APP: {
            platform: CURRENT_PLATFORM,
            store: CURRENT_STORE,
            whitelabelAppId: CURRENT_WHITELABEL_APP_ID,
            apiConfigs,
            version: makeVersion(env, CURRENT_PLATFORM, isDevelopment, webpackVersion),
            sentryVersion: sentryVersion(env, CURRENT_PLATFORM, isDevelopment, webpackVersion),
            commit: '###VERSION###',
            buildNumber: makeBuildNumber(CURRENT_PLATFORM),
            customUrlScheme: process.env.CUSTOM_URL_SCHEME,

            // TODO: Remove once we've got the JWT fixed
            qrveyApiKey: process.env.QRVEY_API_KEY,
        },
        sentry: sentryConfigs(env, isDevelopment, SENTRY_TARGET),
        analytics: getAnalyticsConfig(env, CURRENT_PLATFORM, REMOTE_DEV_HOST),
        killswitchUrl: getKillswitchUrl(env),
    };
};

const sentryVersion = (env, platform, isDevelopment, webpackVersion) => {
    const version = makeVersion(env, platform, isDevelopment, webpackVersion);

    if (platform === PLATFORMS.IOS || platform === PLATFORMS.ANDROID) {
        const buildNumber = makeBuildNumber(platform);
        /** This should follow the same annotation as `scripts/sentry-release.sh:makeVersion` */
        return `${version}-${buildNumber}`;
    }

    return version;
};

const makeVersion = (env, platform, isDevelopment, webpackVersion) => {
    if ((platform === PLATFORMS.WEB || platform === PLATFORMS.DOCKER) && isDevelopment) {
        return 'devVersion';
    }

    if (platform === PLATFORMS.IOS || platform === PLATFORMS.ANDROID) {
        let appVersion;
        try {
            appVersion = require('./app_version.json').appVersion;
        } catch (error) {
            appVersion = 'unknown';
        }
        return `${platform}-${appVersion}`;
    }

    switch (env) {
        case ENV.TEST:
        case ENV.LOCAL:
            return 'devVersion';
        case ENV.STAGING:
            return `staging-${webpackVersion}`;
        case ENV.PRODUCTION:
            return webpackVersion;
        default:
            throw new Error(`Unknown environment: ${env}`);
    }
};

const makeBuildNumber = platform => {
    switch (platform) {
        case PLATFORMS.IOS:
            try {
                return require('./app_version.json').iosBuild;
            } catch (error) {
                return 'unknown';
            }
        case PLATFORMS.ANDROID:
            try {
                return require('./app_version.json').androidBuild;
            } catch (error) {
                return 'unknown';
            }
        case PLATFORMS.WEB:
            return 'web';
        case PLATFORMS.DOCKER:
            return 'docker';
        default:
            throw new Error(`Unknown platform: ${platform}`);
    }
};

const sentryConfigs = (env, isDevelopment, SENTRY_TARGET) => {
    const debug = isDevelopment;
    const development = isDevelopment && !SENTRY_TARGET;
    switch (env) {
        case ENV.TEST:
        case ENV.LOCAL:
        case ENV.STAGING:
        case ENV.PRODUCTION:
            return {
                target: SENTRY_TARGET,
                staging: {
                    debug,
                    development,
                    dsn: 'https://aea9314d34704fc48a61acfa7811a2ee@sentry.famly.de/3',
                },
                production: {
                    debug,
                    development,
                    dsn: 'https://db8a16bb638f4264985f17bb9507c208@sentry.famly.de/4',
                    // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
                    denyUrls: [
                        // Sometimes users accidentally save the app on their computer, which doesn't work:
                        /file:\/\/.*/,
                        // URL when running the app locally:
                        /http:\/\/app\.famly\.local/,
                        // URL when running the app through remote famlydev:
                        /https?:\/\/.*\.famlydev\.co/,
                    ],
                },
            };
        default:
            throw new Error(`Unknown environment: ${env}`);
    }
};

const addDomainsToFamlyUrl = url => allTLDs.map(tld => `${url}${tld}`);

const filterSameDomains = conf => {
    return Object.keys(conf).reduce((obj, key) => {
        const value = conf[key];
        switch (typeof value) {
            case 'string':
                if (value.indexOf('/') === 0) {
                    return obj;
                }
                return Object.assign(obj, {[key]: value});
            case 'object':
                return Object.assign(obj, {[key]: filterSameDomains(value)});
            case 'boolean':
                return Object.assign(obj, {[key]: value});
            default:
                throw Error(`Unable to process api map, got key: "${key}" with value`, value);
        }
    }, {});
};

const contentSecurityPolicy = (platform, unprocessedAPIConfigs, isDevelopment, remoteHost, isFastBuild, imageHost) => {
    const apiConfigs = unprocessedAPIConfigs.map(conf => filterSameDomains(conf));

    const childAndObjectSrc = [
        `'self'`,
        'https://youtube.com',
        'https://player.vimeo.com',
        ...apiConfigs.map(conf => conf.api),
        ...apiConfigs.map(conf => (conf.api ? conf.api.replace('/api', '') : null)),
        'https://*.amazonaws.com',
        'https://rgw.noris.net',
        'https://js.stripe.com',
        'https://fonts.gstatic.com',
        'https://crowdin.com',
        'https://accounts.crowdin.com',
        'data:',
        'https://app.getbeamer.com/',
        'https://intercom-sheets.com', // Necessary to load Intercom's StatusPage integration in a frame
        'https://*.statuspage.io/',
        'https://connect.useparagon.com/',
        'https://connect.paragon.famly.co/',
        'https://calendly.com', // Calendly is used in the Sales-to-Onboarding flow, which is owned by Growth
        'https://*.hotjar.com', // Hotjar is an analytics tool used primarily by Growth for select customers
        'https://www.loom.com', // We sometimes embed Loom videos in Intercom messenger messages/checklists. Without Loom being a part of our CSP rules, this won't work
    ].filter(_ => _);

    switch (platform) {
        case PLATFORMS.WEB:
        case PLATFORMS.DOCKER:
            return {
                'default-src': [`'self'`, ...apiConfigs.map(conf => conf.api)].filter(_ => _),
                'object-src': childAndObjectSrc, // Safari (still) needs object-src.
                'child-src': childAndObjectSrc,
                'frame-src': childAndObjectSrc,

                // Sentry needs this for the Replay feature
                // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/#content-security-policy-csp
                'worker-src': [`'self'`, 'blob:'],

                'script-src': [
                    `'self'`,
                    isDevelopment || isFastBuild ? `http://${APP_DEVELOPMENT_HOST}:*` : null,
                    'https://*.intercom.io',
                    'https://js.intercomcdn.com',
                    'https://js.stripe.com',
                    'https://cdn.crowdin.com',
                    'https://crowdin.com',
                    'https://accounts.crowdin.com',
                    'https://static.getbeamer.com/',
                    'https://app.getbeamer.com',
                    'https://realtime.getbeamer.com/',
                    'https://*.statuspage.io/',
                    'https://cdn.useparagon.com/',
                    'https://connect.paragon.famly.co/',
                    'https://assets.calendly.com',
                    'https://*.hotjar.com',

                    // Qrvey scripts
                    'https://cdn.qrvey.com',
                    'https://cdn.jsdelivr.net/npm/marked/marked.min.js',
                    'https://widgets.reporting.famly.co',
                    'https://widgets.reporting.tryfamly.co',
                    'https://reporting.famly.co',
                    'https://reporting.tryfamly.co',
                ].filter(_ => _),
                'font-src': [
                    `'self'`,
                    'data:',
                    'https://js.intercomcdn.com',
                    'https://fonts.intercomcdn.com',
                    'https://cdn.crowdin.com',
                    'https://fonts.gstatic.com',
                    'https://app.getbeamer.com',

                    // Qrvey fonts
                    'https://widgets.reporting.famly.co',
                    'https://widgets.reporting.tryfamly.co',
                    'https://reporting.famly.co',
                    'https://reporting.tryfamly.co',
                    'https://s3.amazonaws.com',
                ],
                'connect-src': [
                    `'self'`,
                    ...apiConfigs.map(conf => conf.api),
                    ...apiConfigs.map(conf => conf.graphql),
                    ...addDomainsToFamlyUrl('https://up.famly'),
                    ...addDomainsToFamlyUrl('https://app.famly'),
                    ...addDomainsToFamlyUrl('https://tryfamly'),
                    ...addDomainsToFamlyUrl('https://app.tryfamly'),
                    ...addDomainsToFamlyUrl('https://staging.famly'),
                    ...addDomainsToFamlyUrl('https://famlyapi.famly'),
                    ...addDomainsToFamlyUrl('https://famlyapi.tryfamly'),
                    ...addDomainsToFamlyUrl('https://app.staging.famly'),
                    ...addDomainsToFamlyUrl('https://sentry.famly'),
                    isDevelopment || isFastBuild ? `http://${APP_DEVELOPMENT_HOST}:*` : null,
                    isDevelopment || isFastBuild ? 'http://famlyapi-direct.famly.local' : null,
                    isDevelopment ? 'http://fl.famly.local:*' : null,
                    isDevelopment && remoteHost ? `http://${remoteHost}:*` : null,
                    isDevelopment && remoteHost ? `http://*.${remoteHost}:*` : null,
                    'https://*.amazonaws.com',
                    'https://rgw.noris.net',
                    'https://*.intercomcdn.com/',
                    'https://intercom.io',
                    'https://api-ping.intercom.io',
                    '*.intercom.io',
                    'wss://*.intercom.io',
                    'https://*.intercom.io',
                    'https://fl.famly.de',
                    'https://fl.staging.famly.de',
                    'https://uploads.stripe.com/v1/files',
                    'wss://*.app.chime.aws',
                    'https://*.app.chime.aws',
                    'https://backend.getbeamer.com/',
                    'wss://realtime.getbeamer.com/',
                    'https://crowdin.com/api/',
                    'https://zeus.useparagon.com/',
                    'https://zeus.paragon.famly.co/',
                    brightHorizonsApiService,
                    busyBeesApiService,
                    'https://*.hotjar.io',
                    'wss://*.hotjar.com',
                    'https://*.hotjar.com',

                    // Qrvey
                    'https://widgets.reporting.famly.co',
                    'https://widgets.reporting.tryfamly.co',
                    'https://reporting.famly.co',
                    'https://reporting.tryfamly.co',
                ].filter(_ => _),
                'img-src': [
                    `'self'`,
                    ...addDomainsToFamlyUrl('https://famly'), // We have some hard-coded links to images here.
                    ...addDomainsToFamlyUrl('https://img.famly'),
                    ...addDomainsToFamlyUrl('https://img.tryfamly'),
                    ...addDomainsToFamlyUrl('https://img-famlydev.tryfamly'),
                    imageHost ? `http://${imageHost}` : null,
                    'data:',
                    'blob:',
                    'https://*.amazonaws.com',
                    'https://rgw.noris.net',
                    'https://*.intercomcdn.com',
                    'https://*.intercomassets.com',
                    'https://messenger-apps.intercom.io', // Added to be able to load images from Intercom's integrations (e.g. Status Page badge)
                    'https://static.famly.co',
                    'https://cdn.crowdin.com',
                    'https://crowdin-static.downloads.crowdin.com',
                    'https://*.wp.com/',
                    'https://www.gravatar.com/avatar/',
                    'https://app.getbeamer.com/',
                    'https://cdn.useparagon.com/',
                    'https://connect.paragon.famly.co/',
                    'https://*.cloudfront.net',

                    // Images of FB users, pages, etc.
                    'https://graph.facebook.com/',
                    'https://*.fbcdn.net/',

                    // Bright horizons
                    brightHorizonsImgService,

                    // Busy bees
                    busyBeesImgService,

                    // paragon
                    'https://minio.paragon.famly.co',
                    'https://dashboard.paragon.famly.co',
                ].filter(_ => _),
                'media-src': [
                    `'self'`,
                    'blob:',
                    'https://*.amazonaws.com',
                    'https://rgw.noris.net',
                    'https://*.intercomcdn.com',
                    'https://*.intercomassets.com',
                    'https://*.cloudfront.net',
                ],
                'style-src': [
                    `'self'`,
                    `'unsafe-inline'`,
                    'https://cdn.crowdin.com',
                    'https://fonts.googleapis.com',
                    'https://app.getbeamer.com/',

                    // Qrvey
                    'https://widgets.reporting.famly.co',
                    'https://widgets.reporting.tryfamly.co',
                    'https://reporting.famly.co',
                    'https://reporting.tryfamly.co',
                    'https://s3.amazonaws.com',
                ],
                'base-uri': [`'self'`],
            };
        case PLATFORMS.IOS: {
            // To enable js/native communication on iOS
            const defaultSrc = [
                ...apiConfigs.map(conf => conf.api),
                ...apiConfigs.map(conf => conf.graphql),
                '*',
                `'unsafe-inline'`,
                `'unsafe-eval'`,
                'gap://ready',
                'file:',
                'data:',
                'blob:',
            ].filter(_ => _);

            return {
                'default-src': defaultSrc,
                'frame-src': defaultSrc,
                'script-src': defaultSrc,
                'font-src': defaultSrc,
                'connect-src': defaultSrc,
                'img-src': defaultSrc,
                'media-src': defaultSrc,
                'style-src': defaultSrc,

                // Sentry needs this for the Replay feature
                // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/#content-security-policy-csp
                'worker-src': [`'self'`, 'blob:'],
            };
        }
        case PLATFORMS.ANDROID: {
            const defaultSrc = [
                ...apiConfigs.map(conf => conf.api),
                ...apiConfigs.map(conf => conf.graphql),
                '*',
                `'unsafe-inline'`,
                `'unsafe-eval'`,
                'data:',
                'blob:',
            ].filter(_ => _);

            return {
                'default-src': defaultSrc,
                'frame-src': defaultSrc,
                'script-src': defaultSrc,
                'font-src': defaultSrc,
                'connect-src': defaultSrc,
                'img-src': defaultSrc,
                'media-src': defaultSrc,
                'style-src': defaultSrc,

                // Sentry needs this for the Replay feature
                // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/#content-security-policy-csp
                'worker-src': [`'self'`, 'blob:'],
            };
        }
        default:
            throw new Error(`Unknown platform: ${platform}`);
    }
};

const getAnalyticsConfig = (env, platform, remoteDevHost) => {
    const staging = {
        url: 'https://fl.staging.famly.de',
    };

    switch (env) {
        case ENV.LOCAL:
            if (determineMobileApp(platform)) {
                return staging;
            }

            return {
                url: remoteDevHost ? `http://fl.${remoteDevHost}` : 'http://fl.famly.local',
            };
        case ENV.TEST:
        case ENV.STAGING:
            return staging;
        case ENV.PRODUCTION:
            return {
                url: 'https://fl.famly.de',
            };
        default:
            throw new Error(`Unknown environment: ${env}`);
    }
};

const getKillswitchUrl = env => {
    switch (env) {
        case ENV.TEST:
        case ENV.LOCAL:
            return null;
        case ENV.STAGING:
            return 'https://famly-staging-app-killswitch.s3.eu-central-1.amazonaws.com/killswitch';
        case ENV.PRODUCTION:
            return 'https://famly-killswitch.s3.eu-central-1.amazonaws.com/killswitch';
        default:
            return null;
    }
};
