import type React from 'react';
import {List} from 'immutable';

import {type NonEmptyArray} from './non-empty-array';

export function hasValue<T>(candidate: T | undefined | null): candidate is T {
    return candidate !== undefined && candidate !== null;
}

export function nameofFactory<T>() {
    return (name: keyof T) => name;
}

export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

// Construct a type with the properties of T where the those in K are optional
export type Optional<T, K extends keyof T> = Omit<T, K> & {[P in K]?: T[P]};

class Comp<T, U> {
    public readonly apply: (x: T) => U;

    constructor(apply: (x: T) => U) {
        this.apply = apply;
    }

    public with<V>(f: (x: V) => T): Comp<V, U> {
        return new Comp(x => this.apply(f(x)));
    }
}

export function compose<T, U>(f: (x: T) => U) {
    return new Comp(f);
}

export type InferProps<C> = C extends React.ComponentType<infer P> ? P : never;

export type InferRef<T> = T extends React.RefObject<infer P> ? P : never;

export function mapAndReduce<T, U, P>(
    input: List<T> | T[],
    extract: (entity: T) => U,
    reduce: (a: P, b: U) => P,
    initialValue: P,
) {
    return List(input).map(extract).reduce(reduce, initialValue);
}

export function log<T>(tag: string): (val: T) => T;
export function log<T>(tag: string, val: T): T;
export function log<T>(tag: string, val?: T) {
    if (arguments.length === 1) {
        return (val: T) => {
            // eslint-disable-next-line no-console
            console.log(tag, val);
            return val;
        };
    }

    // eslint-disable-next-line no-console
    console.log(tag, val);
    return val;
}

export const concat = <T, P>(t: T[], p: P[]): Array<T | P> => new Array<T | P>().concat(t).concat(p);

export const extend = <A, B>(a: A, b: B): A & B => ({...a, ...b});

export type InferPromiseType<C> = C extends Promise<infer P> ? P : never;

export type Dictionary<T extends string, P> = {
    [key in T]?: P;
};

// Makes at least one of the provided properties on a type required.
// From: https://stackoverflow.com/a/49725198
//
// Usage:
// interface Props { x: string, y: number }
// type PropsWithRequired = RequireAtLeastOne<Props, 'x' | 'y'>;
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
    {
        [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
    }[Keys];

/**
 * Use this as a type-safe alternative to access properties on an object through bracket notation
 * @example
 * getProperty({foo: 'bar', ['wiz-biz']: 123}, 'data-wee') // Typescript error 🙅‍♂️
 * getProperty({foo: 'bar', ['wiz-biz']: 123}, 'wiz-biz') // Typescript is happy 🤠
 */
export const getProperty = <T, K extends keyof T>(o: T, propertyName: K): T[K] => o[propertyName];

/**
 * Use this to extract a subsection of an object while keeping any union structure intact
 * @example
 * type Foo = { bar: 'abc' } & ({ kind: 'a', variant: string } | { kind: 'b', variant: number });
 * getProperty(foo: Foo, 'kind', 'variant'): { kind: 'a', variant: string } | { kind: 'b', variant: number }
 */
export const getProperties = <T, K extends keyof T>(
    o: T,
    ...propertyNames: NonEmptyArray<K>
): DistributivePick<T, K> => {
    return propertyNames.reduce((object, name) => {
        return {
            ...object,
            [name]: o[name],
        };
    }, {} as DistributivePick<T, K>);
};

// A generic function to force TypeScript to fail when it is able to reach this function.
export const exhaustiveCheck = (x: never) => {
    console.warn('Exhaustive check caught following value:', x);
};

// A type to use when there's a problem with typescript trying to infer usages of typescript functions in jsx files, and the type signature becomes wrong.
// using this will make it easier to find all the occurrences where we do this 'hack'.
export type ComponentCastedToAny = any;

/**
 * Use this to Omit a property from a union while keeping its' structure.
 * See https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html#distributive-conditional-types
 *
 * Inspired by: https://stackoverflow.com/questions/57103834/typescript-omit-a-property-from-all-interfaces-in-a-union-but-keep-the-union-s
 */
export type DistributiveOmit<T, K extends keyof T> = T extends unknown ? Omit<T, K> : never;

/**
 * Use this to Pack a property in a union while keeping its' structure.
 */
export type DistributivePack<T, K extends keyof T> = T extends unknown ? Omit<T, K> & {[P in K]: Pack<T[K]>} : never;

/**
 * Use this to Pick properties in a union while keeping its' structure.
 */
export type DistributivePick<T, K extends keyof T> = T extends unknown ? Pick<T, K> : never;

/**
 * Use this to copy properties in a union while keeping its' structure.
 */
export type DistributiveCopy<T, K extends keyof T, V extends string> = T extends unknown
    ? T & {[key in V]: T[K]}
    : never;

/**
 * Use this to make properties optional in a union while keeping its' structure.
 */
export type DistributiveOptional<T, K extends keyof T> = T extends unknown ? Optional<T, K> : never;

/**
 * Transforms a type into an array of the same type
 */
export type Pack<V> = V extends any ? V[] : never;

/**
 * Make sure that an object has at least one of the property
 * from an interface defined.
 * ---------------------------
 * Example:
 * interface MyInterface {
 *  a: number;
 *  b: number;
 *  c: number;
 * }
 *
 * const thisFails: AtLeastOne<MyInterface> = {}; // Type error
 * const thisIsOk: AtLeastOne<MyInterface> = {a: 123}; // All good
 * const thisIsAlsoOk: AtLeastOne<MyInterface> = {a: 123, c: 123}; // All good
 * ---------------------------
 */
export type AtLeastOne<T, U = {[K in keyof T]: Pick<T, K>}> = Partial<T> & U[keyof U];

/**
 * Tells you if the string is part of any of the values in an enum.
 * @param enumToCheck enum to check against
 * @param valueToCheck value to check in the enum itself
 * @returns true if the value is indeed in the enum
 */
export function isValueInEnum<T extends {}>(
    enumToCheck: T,
    valueToCheck: string | T[keyof T],
): valueToCheck is T[keyof T] {
    return Object.values(enumToCheck).includes(valueToCheck as T[keyof T]);
}

export const omit = <T extends object, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> => {
    const copy = {...obj};
    keys.forEach(key => delete copy[key]);
    return copy;
};

/**
 * Functional approach to extracting values from key. Useful for operationg on union structures.
 * Typescript will catch if a union breaks the conditions set up by the callee.
 * @param key: K - The key to extract values from
 * @returns `<T>(target: P) => P[K]` - Where P[K] satisfies T
 *
 * @example
 * ```ts
 * // Can infer the extracted type automatically
 * const extractFoo = extract('foo');
 * const fibs = [{foo: 321}, {foo: 123}];
 * fibs.map(extractFoo); // number
 *
 * // Can constraint the value
 * const extractFoo = extract('foo')<string>;
 * const fibs = [{foo: 321}, {foo: 123}];
 * fibs.map(extractFoo); // 'number' is not assignable to type 'string'
 * ```
 */
export const extract =
    <K extends string>(key: K) =>
    <T>(target: {[k in K]: T}) =>
        getProperty(target, key);

export const identity = <T>(item: T) => item;

declare const brand: unique symbol;
/**
 * Use this to tag/"brand" types. This can be used to identify two of the same type from another,
 * while still keeping their sub-type relation intact.
 *
 * @example
 * ```
 * type CoolString = Brand<string, 'cool-as-a-cucumber'>;
 * const makeStringCool = (id: string) => `transform.${id}` as CoolString;
 *
 * // We can now constrain a function to only accept a certain type of strings!
 * function onlyCoolStringsAllowed(id: CoolString);
 *
 * onlyCoolStringsAllowed('some value'); // Fails
 * onlyCoolStringsAllowed(makeStringCool('some value')) // Passes
 * ```
 */
export type Brand<T, TBrand extends string> = T & {[brand]: TBrand};

// Converts A | B to A & B
export type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

/**
 * Helps debugging complex types recursively
 *
 * the same as Simplify/Prettify from type-fest with the exception of being recursive
 * @example Expand<yourType>
 */
type Primitive = string | number | boolean | bigint | null | void | symbol | Function;
export type Expand<T> = T extends Primitive ? T : {[K in keyof T]: Expand<T[K]>};
